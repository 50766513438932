<template>
<div class="hello">
    <div class="">

        <main>
            <!-- Hero start -->
            <section>
                <div class="hero pad-m">
                    <div class="container">
                        <div class="hero-content m-a">
                            <br><br><br><br><br><br><br>
                            <h1 class="hero-content__title mb-m">Master Linux</h1>
                            <h2 class="subtitle">Get your Linux command line skills up to speed</h2>
                            <br><br><br>
                            <div class="buttons">
                                <a v-on:click="change('linux');" href="#linux" class="btn btn_go">Try an Exercise</a>
                            </div>
<br>
<a href="https://www.producthunt.com/posts/practicelinux?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-practicelinux" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=296520&theme=light" alt="PracticeLinux - Learn Linux, Vim, Git, Tmux, Gpg and more | Product Hunt" style="width: 250px; height: 54px;" width="250" height="54" /></a>

                       </div>
                    </div>
                </div>
                <div class="hero-screen">
                    <div class="hero-screen__bg">
                        <img src="assets/hero_bg_desktop.svg" alt="" />
                    </div>
                    <div class="hero-screen__image m-a">
                        <img src='assets/screen-mockups.svg' alt="" />
                    </div>
                </div>
            </section>
            <!-- Hero end -->
            <!-- Features start -->
            <section id="features" class="pad-l">
                <div class="container m-a">
                    <div class="features-content m-a text-center mb-l">
                       <h2 class="section-title"> Learn vim, gpg, git, tmux and more. It's easy!</h2>
                    </div>
                    <br><br>
                    <div class="features-list">
                        <div class="feature-item">
                            <div class="feature-item__icon">
                                <img src="key.png" alt="work faster">
                            </div>
                            <span class="feature-item__title">Build Muscle Memory</span>
                            <p class="feature-item__paragraph">
                                Be more productive and efficient.
                            </p>
                        </div>
                        <div class="feature-item ">
                            <div class="feature-item__icon">
                                <img src="fast.png" alt="work faster">
                            </div>
                            <span class="feature-item__title">Save time</span>
                            <p class="feature-item__paragraph">
                                Don't waste time with man pages or searching for commands. Memorize them
                            </p>
                        </div>
                        <div class="feature-item ">
                            <div class="feature-item__icon">
                                <img src="power.png" alt="increase productivity with keyboard commands">
                            </div>
                            <span class="feature-item__title">Super Powers</span>
                            <p class="feature-item__paragraph">
                                Increase your Productivity. Learn how to use Linux, Vim, Tmux, Gpg, Grep and more.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Features end -->

            <section id="linux" class="pad-z">
                        <div id="container">
                            <div id="training">
                                <br><br>
                                <h1>Learn {{mytopic}}</h1>

                                
                                    <br>
                            
                            <div id="center">

                                <br><br>

                                

                                <div class="typewriter" v-if="hideAnswers"><h1>
                                    Answer: <span class="answer">{{this.data[index].placeholder}}</span>
                                </h1></div><br>

                                <!-- <div v-for="(item, index) in history" :key="item.solution">
                                    <div class="past" v-if="index < 3">
                                        <div :class=item.valid>{{ item.solution }}</div><br>
                                        <div>{{ item.question }}</div>
                                    </div>
                                </div> -->

                                <input id="ui-key-input" v-model="message" @keydown="keypress" @keydown.esc="escFn" :placeholder=this.data[this.index].question>
                                <br><br>

<div class="col-lg-12">
            <div class="btn btn__primary">Exercise: <span class="score">{{this.index}} / {{this.data.length}} </span> </div>
            &nbsp;
            <div class="btn btn_reset" v-on:click="reset();">Reset</div>
        </div>



                                <br>
                                <span style="padding-right: 8px;"><b>Show answers:</b></span>
                                  <input type="checkbox" v-model=hideAnswers>
                                
                            </div>
                            <br><br><br><br><br>
                        </div>
                        </div>
    </section>

   <!-- Testimonials end -->
    <!-- Pricing start-->
    <section id="pricing" class="pad-l">
        <div class="container">
          
            <div class="pricing-content text-center">
                <h2 class="section-title mb-m">Master Linux.</h2>
                <p class="section-paragraph">It is time to go beyond the dry, boring standard man pages. All-new interactive lessons to help you work faster.</p>
            </div>
            <div class="plans-list">
                <div class="plan-item">
                    <ul class="plan-item__features">
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span>Finally master Linux, Vim and more through fun interactive exercises.</span>
                        </li>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span>We made this website to help you learn commands and shortcuts and save time</span>
                        </li> 
                        <br>  
                        <b></b>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Learn <b>Linux</b> through <b>interactive exercises</b>
                            </span>
                        </li>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Learn <b>hundreds of commands</b> including <b>vim, git, gpg, grep, netstat, tmux</b> and many more
                            </span>
                        </li>
                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Improve your coding speed with <b>vim</b>
                            </span>
                        </li>

                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> No setup, practice in your browser
                            </span>
                        </li>

                        <li class="plan-item__features--item">
                            <ion-icon name="checkmark-outline"></ion-icon>
                            <span><i class="fa fa-check"></i> Infinite <b>practice tests</b>
                            </span>
                        </li>
                    </ul>
                    <div class="plan-item__action">
                        <a href="https://gum.co/eHhaGG" class="btn btn__primary">Practice Linux</a>
                    </div>
                </div>
       
            </div>
        </div>
    </section>
    <!-- Pricing end-->

    <!-- Testimonials start -->
    <section id="testimonials" class="pad-l">

            <div class="text-center mb-l">
                <h2 class="section-title"> Join others who love PracticeLinux.com today!</h2>
            </div>

                <br><br>
            <div class="container">
<div class="testimonials-list">
                <div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="steve.jpeg" alt="">
                    </div>
                    <span class="testimonial-item__name">Steve</span>
                    <div class="testimonial-item__text">
                        <img src="quote.svg" alt="">
                        <blockquote>
                            Nice learning tool for commands
                        </blockquote>
                    </div>
                </div>
                <div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="zhu2.jpeg" alt="">
                    </div>
                    <span class="testimonial-item__name">Zhu
                    </span>
                    <div class="testimonial-item__text">
                        <img src="quote.svg" alt="">
                        <blockquote>
                            I always wanted some interactive way of learning linux, as there are many commands and I keep looking through them in man pages.
                        </blockquote>
                    </div>
                </div>

    <div class="testimonial-item">
                    <div class="testimonial-item__avatar">
                        <img src="ivan.jpeg" alt="">
                    </div>
                    <span class="testimonial-item__name">Ivan
                    </span>
                    <div class="testimonial-item__text">
                        <img src="quote.svg" alt="">
                        <blockquote>
                            Finally mastered vim and git with this tool
                        </blockquote>
                    </div>
                </div>

              </div>
            </div>

    </section>



   <!-- Footer start -->
    <footer id="footer" class="pad-m">
        <div class="container">
            <div class="footer-content mb-l">
                <div class="footer-info">
                    <a href="#" class="footer-info__logo">
                        <img src="assets/logo-footer.svg" alt=""/>
                    </a>
                    <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus, minima vel excepturi fugit cumque autem repudiandae veritatis ex provident qui consequuntur molestias. Et similique excepturi accusamus at in quis eum?</p>
              -->  </div>
                <div class="footer-links">
               
              
                </div>
            </div>
            <div class="footer-bottom">
                <p class="footer-bottom__copyright">Copyright © 2021 Frank Anemaet.</p>
                <ul class="footer-bottom__social">
                    <li>
                        <a class="footer-bottom__social--item" href="#">
                            <ion-icon name="logo-twitter"></ion-icon>
                        </a>
                    </li>
                    <li>
                        <a  class="footer-bottom__social--item" href="#">
                            <ion-icon name="logo-facebook"></ion-icon>
                        </a>
                    </li>                    
                    <li>
                        <a  class="footer-bottom__social--item" href="#">
                            <ion-icon name="logo-linkedin"></ion-icon>                      
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
    <!-- Footer end -->  


        </main>

    </div>
</div>
</template>

<script>
import keyboard from '../assets/js/keyboard.js'

export default {
    data() {
        return {
            show: true,
            message: '',
            index: 0,
            data: [],
            history: [ ],
            hideAnswers: true,
            subscribe: true,
            mytopic: 'linux'
        }
    },
    created: function () {
        this.getSupaData();
        //this.getData();
        if (this.$route.params.id > 0) {
            var key = this.$cookie.get('key');
            if ((key === null) || (key.length < 30)) {
                //router.replace("/login");
                window.location.href = 'https://gum.co/eHhaGG';
            }
            this.subscribe = false;
        }

    },
    methods: {
        keypress(e) {
           keyboard.keypress(e);

           if (keyboard.message.length >= 1) {           
             this.message = keyboard.message;
           }
        },
        change(topic) {
            console.log(topic);
            this.index = 0;
            this.mytopic = topic;
            this.getSupaData();
        },
        escFn() {
            this.message = "ESC";
        },
        check() {
            /*console.log(this.message);
            console.log(this.data[this.index].solution);
            if (this.message === this.data[this.index].solution) {
                this.index += 1;
                this.message = '';
            }*/
        },
        reset: function() {
          this.index = 0;
          this.getSupaData();
          this.history = [];
        },
        async getSupaData() {

              fetch('../../../api/json/' + this.mytopic + '.json')
                .then(response => response.json())
                .then(data => (this.data = data ))
                .then(response => {
                    this.answer = this.data[this.index].placeholder;
                    this.response = response;

                    //console.log(this.routeid);
                    //console.log(this.$route.params.id);
                    this.data = this.data.filter((v) => {
                       return v.level <= Number(0)+1 
                    })

                });

            // this.answer = d;
        },
    },
    computed: {},
    watch: {
        'message': function (val, preVal) {
            console.log(val + " " + preVal);
            console.log('message = ' + this.message);
            console.log('solution = ' + this.data[this.index].solution);

            // infinite training
            if (this.index >= this.data.length - 1) {
                this.index = 0;
                //shuffleArray(this.data);
                document.getElementById("pricing").scrollIntoView();
            }

            // convert to string if type if number
            if (typeof(this.data[this.index].solution) === 'number') {
                this.data[this.index].solution = this.data[this.index].solution.toString();
            }


            if (this.message === this.data[this.index].solution) {
                if (val != '')
                    this.history.unshift({
                        'solution': this.message,
                        'valid': 'true',
                        'question': this.data[this.index].question
                    });

                this.index += 1;

                this.message = '';

            } else if (this.data[this.index].solution.length == 1) {

                if (val != '')
                    this.history.unshift({
                        'solution': this.message,
                        'valid': 'false',
                        'question': this.data[this.index].question
                    });

                this.message = '';
            } else {
                // answer longer than one 
                if (this.message.length == this.data[this.index].solution.length) {
                    
                    if (this.message !== this.data[this.index].solution) {
                        if (val != '')
                            this.history.unshift({
                                'solution': this.message,
                                'valid': 'false',
                                'question': this.data[this.index].question
                            });

                        this.message = '';
                    }
                }
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import '../assets/hero/css2.css';
@import '../assets/hero/normalize.css';
@import '../assets/hero/style.css';
@import '../assets/hero/responsive.css';


#testimonials {
    background: #f9fafb;
}

#footer {
    background: #f9fafb;
}

.testimonial-item__avatar {
    border: #e8eaeb;
}

.hero-content__title {
    color: #111827;
}

#subtitle {
    color: #666;
}

.answer {
    color: cornflowerblue;
}

.hero {
background: #fff;
}

@media screen and (min-width : 1024px){
#training {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width:50%;
    margin: 0 auto;
}
}

.pad-m {
  padding-bottom: 4rem;
}

.pad-l {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.pad-z {
    padding-top: 6rem;
    padding-bottom: 10rem;
}

.buttons {
    color:white;
}
/* left, center, right */
#container {
    /*width: 100%;*/
}

@media screen and (max-width : 1024px){
    #center {
        width: 100%;
    }

    #ui-key-input {
        width: 80%;
    }
}

@media screen and (min-width : 1024px){
    #left {
        float: left;
        width: 250px;
        text-align: right;
    }

    #right {
        float: right;
        width: 100px;
    }

    #center {
        width: 640px;
        margin: 0 auto;
    }
}


/* slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #2196F3;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/* end slider */


.feature-item__icon {
    background: white;
}

.subtitle a {
    color: rgb(0, 101, 255);
}

.hero-content__title {
    font-size: 6.8rem;
}

@media screen and (min-width : 1024px){
.btn_go {
    margin-right: 8px;
    margin-bottom: 16px;
    background: rgb(0, 71, 179);
    color: white;
    width: 25%;
    padding-top: 16px;
    padding-bottom: 16px;
    border-radius: 6px;
}
}

@media screen and (max-width : 1024px){
.btn_go {
    margin-bottom: 16px;
    background: rgb(0, 71, 179);
    color: white;
    border-radius: 6px;
}
}



.true {
    color: green;
}

.false {
    color: red;
}

.past {
    font-size: 24px;
    text-align: left;
    padding-top: 14px;
    position: relative;
    background-color: rgb(244,245,247);
    margin-right: -15px;
    margin-left: -15px;
    padding-bottom: 14px;
    padding-left: 14px;
    border-radius: 6px;
    border: 1px solid gray;
    margin-bottom: 20px;
    margin: 0 auto;
    width: 90%;
    margin-bottom: 16px;
}

#ui-key-input {
    font-size: 24px;
    text-align: left;
    padding-top: 14px;
    position: relative;
    background-color: white;
    padding-bottom: 14px;
    padding-left: 14px;
    border-radius: 6px;
    border: 1px solid gray;
    width: 90%;
}

.plan-item:nth-child(1),
.plan-item:nth-child(3) {
    border: 2px solid rgba(0, 0, 0, 0.1);
}

#pricing {
    color: black;
}

.kbc-button {
    padding: 6px;
    margin-top: 10px;
}

.btn_reset {
    background: white;
    opacity: 1;
    font-size: 1.6rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.8rem 2.6rem;
    transition: transform 100ms ease-in-out;
    border-radius: 3px;
    color: var(--text-dark);
    display: inline-block;
    text-align: center;
    border: 1px solid #dadce0;
    color: #000;
    border: 1px solid black;
    flex: 1 1 auto;
}

.btn {
    font-size: 1.125em;
    font-family: 'Roboto', sans-serif;
    letter-spacing: -0.025rem;
    font-weight: 400;
    font-family: 'Roboto', sans-serif;
}

.btn:hover {
    color: white;
}

h2.subtitle {
    color: #000;
    font-family: 'Roboto', sans-serif;
    font-size: 1.125em;
    line-height: 1.625;
    font-weight: 300;
}

.subtitle {
    color: white;
}

#parent {
    width: 100%;
    height: 400px;
    display: block;
    position: relative;
}

#editor {
    position: absolute;
    /* Added */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    border-radius: 8px;
}

.testimonial-item__avatar {
    height: 6rem;
    width: 6rem;
    position: absolute;
    top: -6.4rem;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 50%;
    border-top: 4px solid #4704a1;
    border-left: 4px solid #4704a1;
}
</style>
